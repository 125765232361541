

























































































































































































































































































































































































































































































































































/*去除页眉页脚*/
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}
html{
  background-color: #FFFFFF;
  margin: 0;  /* this affects the margin on the html before sending to printer */
}
body{
  border: solid 1px blue ;
  margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
}
/*去除页眉页脚*/
.doorStickerBox{
  border: 1px solid #ddd;
  .doorStickerTitle{
    padding: 8px 20px;
    position: relative;
    &>h3{
      font-size: 22px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &>span{
      font-size: 16px;
      position: absolute;
      height: 24px;
      top: 20px;
      right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &>div{
      margin-top: 8px;
      border: 1px solid #999;
      padding: 4px 0;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.doorStickerList{
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .doorStickerItem{

    &.column-1{
      width: 100%;
      min-width: 100%;
    }
    &.column-2{
      width: 50%;
      min-width: 50%;
    }
    &.column-3{
      width: 33.33%;
      min-width: 33.33%;
    }
    &.column-4{
      width: 25%;
      min-width: 25%;
    }
    &.column-5{
      width: 20%;
      min-width: 20%;
    }
    .doorStickerItemInner{
      margin: 0 4px 8px;
      padding: 10px;
      border: 1px solid #999;
      border-radius: 4px;
      min-height: 54px;
      .doorStickerText{
        display: flex;
        justify-content: space-between;
        span{
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:nth-child(1){
            max-width: calc(100% - 72px);
          }
          &:nth-child(2){
            width: 72px;
            text-align: right;
          }
          &.full{
            max-width: 100%;
          }
        }

      }
    }

  }
}
